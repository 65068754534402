import React, { useState, useEffect } from 'react';
import CustomSidebar from './CustomSidebar';
import { MapContainer, TileLayer, CircleMarker, Popup, Marker} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { renderToStaticMarkup } from 'react-dom/server';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from './auth';
import { useNavigate } from 'react-router-dom';

const worldBounds = new L.LatLngBounds(
  new L.LatLng(-88, -180),
  new L.LatLng(88, 180)
);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const customIcon = new L.divIcon({
  html: renderToStaticMarkup(<ApartmentIcon fontSize="medium" />),
  className: 'custom-marker-icon',
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const hqLocation = { lat: 26.1086525, lng: -80.2609162 };
const crcsaLocation = { lat: -33.9761999, lng: 18.4624045};

const incidentTypeColors = {};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const WorldMapPage = () => {
  const [apiResult, setApiResult] = useState(null);
  const { accounts } = useMsal();
  const navigate = useNavigate();

  console.log('Pre-async call');

  const handleSearch = async () => {
    console.log('Doing the async, I guess');
    const accessToken = await getAccessToken();
    
    if (accessToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      
      fetch(`https://fp-api.focuspointintl.com/api/casedata`, { headers })
        .then((response) => response.json())
        .then((data) => {
          const apiData = data;

          // Assign random colors to unique incident types
          apiData.forEach((record) => {
            if (!incidentTypeColors[record.incidenttype]) {
              incidentTypeColors[record.incidenttype] = getRandomColor();
            }
          });

          console.log('API Response:', apiData);
          setApiResult(apiData);
        })
        .catch((error) => {
          console.error('API Error:', error);
        });
    } else {
      console.error('Access token retrieval/refresh failed');
    }
  };

  useEffect(() => {
    if (accounts.length === 0) {
      navigate('/');
    }
  }, [accounts, navigate]);

  useEffect(() => {
    // Load data when the component mounts
    handleSearch();
  }, []);

  return (
    <div style={{ display: 'flex', height: '95vh' }}>
      <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '200px' }}>
        <CustomSidebar />
      </div>
      <div style={{ flex: 1, marginLeft: '260px', overflow: 'hidden' }}>
        <h3>Command Overview</h3>
        <div style={{ height: 'calc(100vh - 64px)' }}>
          <MapContainer
            center={[0, 0]}
            zoom={2}
            style={{ width: '100%', height: '100%' }}
            maxBounds={worldBounds}
            maxBoundsViscosity={1}
            minZoom={2}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Plot markers for manually entered locations */}
            <Marker position={hqLocation} icon={customIcon}>
              <Popup>FocusPoint HQ</Popup>
            </Marker>
            <Marker position={crcsaLocation} icon={customIcon}>
              <Popup>FocusPoint CRCSA</Popup>
            </Marker>

            {/* Plot dot markers for GPS locations from API with incident type-based colors */}
            {apiResult &&
              apiResult.map((record) => (
<Marker
  key={record.id}
  position={[record.gpsLat, record.gpsLong]}

>
  <Popup>
    {record.customerName} - {record.deviceId}
  </Popup>
</Marker>
              ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default WorldMapPage;
