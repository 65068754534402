import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { pca, login, checkAuthentication } from './auth';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignInSide from './SignInSide';
import GlobalSearchPage from './GlobalSearchPage';
import WorldMapPage from './WorldMapPage';


function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const handleAuthentication = async () => {
      const isAuthenticated = await checkAuthentication();
      setAuthenticated(isAuthenticated);      
    };

    handleAuthentication();
  }, []);

  const handleSignIn = () => {
    if (!authenticated) {
      login();
    }
  };

  return (
    <MsalProvider instance={pca}>
      <Router>
        <div className="app-container">
          <div className="sidebar">
            {/* Sidebar content goes here */}
          </div>
          <div className="main-content">
            <Routes>
              <Route
                path="/"
                element={
                  authenticated ? (
                    <Navigate to="/global-search" replace={true} />
                  ) : (
                    <SignInSide handleSignIn={handleSignIn} setAuthenticated={setAuthenticated} />
                  )
                }
              />
              <Route path="/global-search" element={<GlobalSearchPage />} />
              <Route path="/world-map" element={<WorldMapPage />} /> 
            </Routes>
          </div>
        </div>
      </Router>
    </MsalProvider>
  );
}

export default App;
