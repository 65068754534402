import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { login, checkAuthentication } from './auth';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function SignInSide({ handleSignIn, setAuthenticated }) {
  const { instance } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const handleAuthentication = async () => {
      await instance.handleRedirectPromise();
      const isAuthenticated = await checkAuthentication();
      console.log('Are we authenticated: ', isAuthenticated);
      setIsAuthenticated(isAuthenticated);
    };

    handleAuthentication();
  }, [setAuthenticated]);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={defaultTheme}>
        <div>
          <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
              item
              xs={12}
              sm={4}
              md={7}
              sx={{
                backgroundImage: 'url(https://www.focuspointintl.com/images/GS-CRC.png)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 4,
                }}
              >
                <img
                  src="https://www.focuspointintl.com/images/logo-cap-or.png"
                  alt="Logo"
                  style={{
                    width: '300px',
                    height: 'auto',
                    marginBottom: '30%',
                  }}
                />
                {/* Add any additional logos here */}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  FocusPoint Calculon
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignIn}
                  disabled={isAuthenticated}
                  sx={{ mt: 2 }}
                >
                  {isAuthenticated ? 'Signed In' : 'Sign In with Office 365'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
}
