import { Configuration, PublicClientApplication, InteractionType } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: 'https://gs.focuspointintl.com',
  },
};

const pca = new PublicClientApplication(configuration);

const login = () => {
  pca.loginRedirect();
};

const logout = () => {
  pca.logout();
};

const checkAuthentication = async () => {
  await pca.handleRedirectPromise();
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    const account = accounts[0];
    const authTokens = account.idTokenClaims;
    const now = Math.floor(Date.now() / 1000); // Get the current time in seconds

    if (authTokens.exp && authTokens.exp < now) {
      console.log('Token has expired');
      try {
        // Attempt to acquire a new token silently
        await pca.acquireTokenSilent({
          account: account,
          scopes: ["6a191025-74c1-4d67-a315-0d1225e1c5ad/.default"],
          authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
          forceRefresh: false,
          interactionType: InteractionType.Silent,
        });

        console.log('Token refreshed successfully');
        return true;
      } catch (error) {
        console.error('Token refresh error:', error);
        logout(); // Redirect to the login page
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

const getAccessToken = async () => {
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const silentRequest = {
        account: accounts[0],
        scopes: ["6a191025-74c1-4d67-a315-0d1225e1c5ad/.default"], // Specify the required scopes for your API
        forceRefresh: false, // Set to true if you want to force a token refresh
        interactionType: InteractionType.Silent, // Specify the interaction type as silent
      };

      const authResult = await pca.acquireTokenSilent(silentRequest);

      return authResult.accessToken;
    } catch (error) {
      console.error("Token acquisition error:", error);

      // If token acquisition fails due to expiration, attempt token refresh
      if (error.errorMessage.includes("interaction_required")) {
        try {
          const interactiveRequest = {
            scopes: ["6a191025-74c1-4d67-a315-0d1225e1c5ad/.default"], // Specify the required scopes for your API
            prompt: "select_account", // Prompt the user to select an account for interactive authentication
          };

          const authResult = await pca.acquireTokenRedirect(interactiveRequest);

          return authResult.accessToken;
        } catch (error) {
          console.error("Token refresh failed:", error);
          return null;
        }
      }

      return null;
    }
  } else {
    console.error("Account not available");
    return null;
  }
};

export { pca, login, logout, checkAuthentication, getAccessToken };