import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import CustomSidebar from './CustomSidebar';
import Table from './Table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { pca, getAccessToken } from './auth';



const columns = [
  { label: "First Name", accessor: "First", sortable: true },
  { label: "Last Name", accessor: "Last", sortable: true },
  { label: "Email", accessor: "Email", sortable: true, sortbyOrder: "desc" },
  { label: "Phone", accessor: "Phone", sortable: true },
  { label: "Membership (FP)", accessor: "MembershipINT", sortable: true },
  { label: "Membership (Client)", accessor: "MembershipEXT", sortable: true },
  { label: "Parent company", accessor: "Parent", sortable: true },
  { label: "Start", accessor: "Start", sortable: true },
  { label: "End", accessor: "End", sortable: true },
  { label: "Division", accessor: "Company", sortable: true },
  { label: "Service", accessor: "Type", sortable: true },
  
];

const GlobalSearchPage =  () => {
  const [apiResult, setApiResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  const { accounts } = useMsal();
  const navigate = useNavigate();

  const handleSearch = async () => {
    setIsLoading(true);
    const accessToken = await getAccessToken();
    //const accessToken = accounts.length > 0 ? accessToken2 : null;
    //console.log('GS Accounts:', accounts);
    // Set up the basic authentication headers
    
    if (accessToken) {

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    //console.log('Headers: ', headers)
    // Make the API call
    fetch(`https://fp-api.focuspointintl.com/api/data/?searchpass=${searchTerm}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        // Process the API response data
        const apiData = data;
        //console.log('API Response:', apiData);
        setApiResult(apiData);
        setIsLoading(false);
        setShowMessage(false);
        // Store the data in state or perform any other necessary operations
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error('API Error:', error);
        setIsLoading(false);
      });

    } else {
      console.error('Access token retrieval/refresh failed');
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


 
  useEffect(() => {
    if (accounts.length === 0) {
      navigate('/');
    }
  }, [accounts, navigate]);

  return (
    <div style={{ display: 'flex'}}>
            <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '200px' }}>
        <CustomSidebar />
      </div>

      <div style={{ flex: 1, marginLeft: '260px', overflowY: 'auto'}}>
        <h1>Global Search Page</h1>
           {/* Add your global search functionality here */}
          
           <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <TextField
            label="Search"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            variant="outlined"
            style={{ marginRight: '1rem', width: '300px' }}
          />
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>
          
        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {apiResult !== null ? (
  apiResult.length > 0 ? (
    <Table caption={`Calculon has found the information that you seek: ${apiResult.length} results`} data={apiResult} columns={columns} />
  ) : (
    <p>No results found.</p>
  )
) : (
  <>
    {showMessage && <p><b><i>Instructions</i></b><br /><br />When searching for a customer, please remember that we have different types of accounts with different structures. A parent account can have multiple sub-divisions.  A customer can call FocusPoint and reference the name of the subdivision instead of the parent account. When using the FPI Custom Search, they will reflect under the parent account as well as the abbreviation of the sub-division. You can use this tool to cross-reference what is on CAP Advantage and to verify if the customer calling in is indeed active.<br /><br /><b><i>Disclaimer</i></b><br /><br />Please note that the FPI Search Engine may not always be 100 percent accurate.  Some of FocusPoint’s CAP MED accounts conduct their own verification and then warm transfer the customer to our CRC. In this example, FocusPoint needs to request proof of employment such as a written letter from the organization's letterhead, an email confirmation, etc. <br /><br />Also, some accounts with subdivisions may not have all the active customers in our system. <br /><br />Any questions or concerns, please go through your appropriate supervisor and escalate as necessary.
</p>}
  </>
)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchPage;
