import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
//import 'react-pro-sidebar/dist/css/sidebar.css';
import { FiSearch, FiLogOut, FiBookOpen, FiMap } from 'react-icons/fi';

const CustomSidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Implement your logout logic here
    navigate('/');
  };

  const handleNavigateToMap = () => {
    // Navigate to the "World Map" page when the menu item is clicked
    navigate('/world-map');
  };

  const handleNavigateToSearch = () => {
    // Navigate to the "World Map" page when the menu item is clicked
    navigate('/global-search');
  };

  return (
    <Sidebar style={{ color: '#fff' }} >
      <div style={{ backgroundColor: '#333',height: '100vh', display: 'flex', flexDirection: 'column' }} >
        <div>
        <img src="https://www.focuspointintl.com/images/logo-cap-or-White.png" alt="Logo" style={{ width: '100%', height: 'auto' }} />
        </div>
        <div style={{ flex: 1 }}>
          <Menu iconShape="square" menuItemStyles={{
  button: {
        backgroundColor: '#333',
        '&:hover': {
           backgroundColor: '#111',
        },
    },
}}>
            <MenuItem icon={<FiSearch />} onClick={handleNavigateToSearch}> Global Search</MenuItem>
            {/* Add more menu items here */}
            <SubMenu label="Documentation" icon={<FiBookOpen />}>
              <MenuItem>Introduction</MenuItem>
              <MenuItem>Getting Started</MenuItem>
            </SubMenu>
            <MenuItem icon={<FiMap />} onClick={handleNavigateToMap}>
              World Map
            </MenuItem>
          </Menu>
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Menu menuItemStyles={{
  button: {
        backgroundColor: '#333',
        '&:hover': {
           backgroundColor: '#111',
        },
    },
}}>
            <MenuItem icon={<FiLogOut />} onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default CustomSidebar;
